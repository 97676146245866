import { ProduitNode } from '@innedit/innedit-type';
import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../../../components/Content';
import Layout, { LayoutProps } from '../../../components/Layout';
import Produit from '../../../components/Produit';

const PageFrProduitTemplate: FC<PageProps<{ produit: ProduitNode }>> =
  function (props) {
    const {
      data: { produit },
      path,
    } = props;

    const { t } = useTranslation();

    const { description, metaKeywords, pathname, title } = produit;

    let { metaDescription } = produit;

    const name = produit.traductions?.en?.name
      ? (produit.traductions.en.name as string)
      : produit.name;

    const breadcrumbs =
      !produit.hasInventory || produit.qtyAvailable > 0
        ? [
            {
              mobile: true,
              to: t('pages.inventaire.pathname'),
              value: t('pages.inventaire.title'),
            },
          ]
        : [
            {
              mobile: true,
              to: t('footer.categories.values.vendus.pathname'),
              value: t('footer.categories.values.vendus.label'),
            },
          ];

    const basTitle = title || name;

    const basKeywords =
      metaKeywords ||
      basTitle
        .split(' ')
        .filter(k => k.length > 2)
        .join(',')
        .split("'")
        .filter(k => k.length > 2)
        .join(',');

    if (produit.traductions?.en) {
      metaDescription =
        (produit.traductions.en.description as string) ||
        (produit.traductions.en.name as string);
    } else {
      metaDescription = metaDescription || description || name;
    }

    const layoutProps: LayoutProps = {
      breadcrumbs,
      // image,
      breadcrumbsTitle: produit.traductions?.en?.name
        ? (produit.traductions.en.name as string)
        : produit.name,
      head: {
        language: 'en',
        meta: [
          {
            content: metaDescription,
            name: 'description',
          },
          {
            content: basKeywords,
            name: 'keywords',
          },
        ],
      },
      pathname: `/en/product/${pathname}/`,
      title: basTitle,
      titleClassName:
        'max-w-screen-lg my-20 mx-12 md:mx-auto text-3xl md:text-5xl text-center',
    };

    return (
      <Layout {...layoutProps}>
        <Content>
          <Produit data={produit} pathname={path} />
        </Content>
      </Layout>
    );
  };

export default PageFrProduitTemplate;

export const query = graphql`
  query ($id: String!) {
    produit(id: { eq: $id }) {
      id
      condition
      depth
      description
      hasInventory
      height
      hidden
      isALot
      isOnSale
      medias {
        id
        name
        origin
        pathname
        height
        width
      }
      name
      pathname
      price
      priceDisplayMode
      qtyAvailable
      qtyByNumber
      qtyMax
      qtyMin
      shippingMode
      shortname
      sku
      title
      traductions {
        en {
          description
          name
          shortname
        }
      }
      weight
      width
    }
  }
`;
